'use client';

import { useEffect, useState } from 'react';
import { Content } from '@prismicio/client';
import { SliceZone } from '@prismicio/react';

// Slices
import { components as homeComponents } from '@/slices/home';
import { components as sharedComponents } from '@/slices/shared';

type HomeProps = {
  slices: Content.HomeDocumentDataSlicesSlice[];
};

export const Home = ({ slices }: HomeProps) => {
  const [isInitialised, setIsInitialised] = useState<boolean>(false);

  useEffect(() => {
    document.body.classList.add('home');
    setIsInitialised(true);
  }, []);

  return (
    <>
      {isInitialised && (
        <SliceZone
          slices={slices}
          components={{ ...homeComponents, ...sharedComponents }}
        />
      )}
    </>
  );
};
